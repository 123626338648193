/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
import {x} from '@xstyled/styled-components';
import {Image, Quote, QuoteAuthor} from "smooth-doc/components";
function _createMdxContent(props) {
  const _components = Object.assign({
    h1: "h1",
    a: "a",
    div: "div",
    p: "p",
    pre: "pre",
    code: "code"
  }, _provideComponents(), props.components);
  return React.createElement(React.Fragment, null, React.createElement(_components.h1, {
    id: "bounded-context-canvas",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#bounded-context-canvas",
    "aria-label": "bounded context canvas permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Bounded Context Canvas"), "\n", React.createElement(_components.p, null, React.createElement(_components.a, {
    href: "https://github.com/ddd-crew/bounded-context-canvas"
  }, "https://github.com/ddd-crew/bounded-context-canvas")), "\n", React.createElement(_components.p, null, "The Bounded Context Canvas is a collaborative tool for designing and documenting the design of a single bounded context."), "\n", React.createElement(_components.p, null, "It identifies 3 types of inbound and outbound communication objects: Queries, Commands and Events"), "\n", React.createElement(_components.p, null, "It also includes the Ubiquitous Language for the Bounded Context: Aggregates, Policies and Business Decisions"), "\n", React.createElement(Image, {
    src: "https://raw.githubusercontent.com/ddd-crew/bounded-context-canvas/master/tools/excalidraw-version/bounded-context-canvas-excalidraw.svg",
    alt: "Bounded Context Canvas",
    width: "100%"
  }), "\n", React.createElement(_components.p, null, "ZDL can map each of those elements into a developer friendly and machine-readable format."), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-zdl"
  }, "apis {\r\n    asyncapi(provider) MyEventsApi {\r\n        uri \"src/main/resources/asyncapi.yml\"\r\n    }\r\n    openapi(provider) MyRestApi {\r\n        uri \"src/main/resources/openapi.yml\"\r\n    }\r\n    asyncapi(client) ThirdPartyEventsApi {\r\n        uri \"https://.../asyncapi.yml\"\r\n    }\r\n    openapi(client) ThirdPartyRestApi {\r\n        uri \"https://.../openapi.yml\"\r\n    }\r\n}\r\n// ============\r\n\r\n@aggregate\r\nentity MyEntity {}\r\n\r\ninput MyEntityInput {}\r\n\r\noutput MyEntityReadModel {}\r\n\r\npolicies (MyEntity) {\r\n    policy001 \"Describe here the content of this business rule\"\r\n}\r\n\r\nservice MyService for (MyEntity) {\r\n\r\n    // inbound async command\r\n    @policies(policy001)\r\n    @asyncapi({api: ThirdPartyRestApi, operationId: \"createMyEntity\"})\r\n    createMyEntity(MyEntityInput) MyEntity withEvents ThirdPartyAsyncRequest MyEntityUpdatedEvent // outbound events\r\n\r\n    // inbound sync command\r\n    @rest({api: MyRestApi, operationId: \"showMyEntityById\"})\r\n    @uses({api: ThirdPartyRestApi, operationId: \"fetchRelatedData\"}) // outbound query\r\n    synchMyEntityWithThirdPartyData(id) MyEntityReadModel\r\n\r\n    // inbound query\r\n    @rest({api: MyRestApi, operationId: \"listMyEntities\"})\r\n    listMyEntities() MyEntityReadModel[]\r\n}\r\n\r\n\r\n@asyncapi({api: MyEventsApi, operationId: \"onMyEntity\"}) // outbound event\r\nevent MyEntityUpdatedEvent {}\r\n\r\n@asyncapi({api: ThirdPartyEventsApi, operationId: \"thirdPartyRequestsChannel\"}) // outbound command\r\nevent ThirdPartyAsyncRequest {}\r\n\n")));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
